// Configuration des contraintes pour la caméra
const constraints = {
  audio: true,
  video: {
    width: { ideal: 2560 },
    height: { ideal: 1440 },
    facingMode: { ideal: "environment" },
    frameRate: { min: 25, ideal: 30, max: 30 },
  },
};

// Fonction pour démarrer la caméra
const startCamera = (constraints, callback) => {
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      // Sauvegarde du flux dans window pour un accès global
      window.stream = stream;
      callback({ success: true, stream });
    })
    .catch((error) => {
      console.error("Erreur lors du démarrage de la caméra :", error);
      callback({ success: false, error });
    });
};

// Fonction pour arrêter la caméra
const stopCamera = (callback) => {
  if (window.stream) {
    window.stream.getTracks().forEach((track) => track.stop());
    window.stream = null;
    callback({ success: true });
  } else {
    callback({
      success: false,
      error: "Aucun flux actif trouvé pour arrêter la caméra.",
    });
  }
};

// Fonction pour activer ou désactiver le son
const updateAudio = (audioMuted) => {
  if (window.stream) {
    const audioTrack = window.stream.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !audioMuted; // true pour activer, false pour désactiver
      return { success: true };
    }
  }
  return { success: false, error: "Aucune piste audio active trouvée." };
};

// Fonction pour activer ou désactiver la vidéo
const updateVideo = (cameraDisabled) => {
  if (window.stream) {
    const videoTrack = window.stream.getVideoTracks()[0];
    if (videoTrack) {
      videoTrack.enabled = !cameraDisabled; // true pour activer, false pour désactiver
      return { success: true };
    }
  }
  return { success: false, error: "Aucune piste vidéo active trouvée." };
};

// Fonction pour inverser la caméra entre avant et arrière
const reverseCamera = (callback) => {
  stopCamera((result) => {
    if (result.success) {
      // Inversion de la caméra (avant <-> arrière)
      constraints.video.facingMode.ideal =
        constraints.video.facingMode.ideal === "environment"
          ? "user"
          : "environment";

      // Relance de la caméra avec les nouvelles contraintes
      startCamera(constraints, (startResult) => {
        callback(startResult);
      });
    } else {
      callback({ success: false, error: "Impossible d'inverser la caméra." });
    }
  });
};

// Export des fonctions
export {
  constraints,
  startCamera,
  stopCamera,
  updateAudio,
  updateVideo,
  reverseCamera,
};
