import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Nav, Loader, Button } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";

import Folder from "../../components/folder/Folder";
import Contact from "../../components/folder/Contact";
import Picture from "../../components/folder/Picture";
import Historical from "../../components/folder/Historical";
import ModalEditFolderMessage from "../../components/folder/ModalEditFolderMessage";

import axiosConfig from "../../utils/axiosConfig";

const Home = ({ refWebRTC }) => {
  const { token } = useParams(); // Remplace this.props.match.params.token
  const navigate = useNavigate();

  const [isLoad, setIsLoad] = useState(true);
  const [isLoadPictures, setIsLoadPictures] = useState(true);
  const [folderData, setFolderData] = useState({});
  const [pictures, setPictures] = useState([]);
  const [historical, setHistorical] = useState([]);
  const [isModalEditFolderMessage, setIsModalEditFolderMessage] =
    useState(false);

  useEffect(() => {
    fetchFolder();
  }, []);

  const fetchFolder = (includePictures = true) => {
    setIsLoad(true);

    axiosConfig("folder/get-folder", { data: { token } })
      .then((res) => {
        if (res.data.success) {
          const { folder, historical } = res.data;
          setFolderData(folder);
          setHistorical(historical);
          setIsLoad(false);

          if (includePictures) fetchPictures(folder.id);
        } else {
          handleError("Erreur lors de la récupération du dossier.");
        }
      })
      .catch(() => handleError("Erreur lors de la récupération du dossier."));
  };

  const fetchPictures = (id) => {
    setIsLoadPictures(true);

    axiosConfig("folder/get-pictures", { data: { id } })
      .then((res) => {
        if (res.data.success) {
          setPictures(res.data.pictures);
          setIsLoadPictures(false);
        } else {
          handlePicturesError();
        }
      })
      .catch(handlePicturesError);
  };

  const handlePicturesError = () => {
    setIsLoadPictures(false);
    setPictures([]);
  };

  const handleError = (message) => {
    alert(message);
    setIsLoad(false);
    navigate("/home"); // Remplace this.props.history.push("/home")
  };

  const updateFolderStatus = (status) => {
    setIsLoad(true);

    axiosConfig("folder/update-status-folder", {
      data: { status, id: folderData.id },
    })
      .then((res) => {
        if (res.data.success) {
          setFolderData((prev) => ({ ...prev, status }));
          setIsLoad(false);
        } else {
          handleError("Erreur lors de la mise à jour du statut.");
        }
      })
      .catch(() => handleError("Erreur lors de la mise à jour du statut."));
  };

  return (
    <>
      <Nav
        title={`N° dossier ${folderData.reference}`}
        className="mb-10 mb-20-xl"
        back
        onBack={() => navigate("/home")} // Utilisation de useNavigate
      >
        {folderData.status >= 2 && folderData.status < 4 && (
          <Button
            className="ml-5"
            text="Clôturer"
            onClick={() => updateFolderStatus(4)}
          >
            <FontAwesomeIcon icon={faLock} />
          </Button>
        )}
        {folderData.status === 4 && (
          <Button
            className="ml-5"
            text="Ouvrir"
            onClick={() => updateFolderStatus(2)}
          >
            <FontAwesomeIcon icon={faLockOpen} />
          </Button>
        )}
      </Nav>

      <Row>
        <Col xs={12} md={6} lg={8} className="mb-10 mb-20-xl">
          <Folder {...folderData} getFolder={fetchFolder} />
        </Col>
        <Col xs={12} md={6} lg={4} className="mb-10 mb-20-xl">
          <Contact
            {...folderData}
            openModalEdit={() => setIsModalEditFolderMessage(true)}
            getFolder={fetchFolder}
            refWebRTC={refWebRTC}
          />
        </Col>
        <Col xs={12} className="mb-10 mb-20-xl">
          <Picture
            isLoad={isLoadPictures}
            data={pictures}
            deletePicture={(index) => {
              const updatedPictures = [...pictures];
              updatedPictures.splice(index, 1);
              setPictures(updatedPictures);
            }}
          />
        </Col>
        <Col xs={12} className="mb-10 mb-20-xl">
          <Historical historical={historical} />
        </Col>
      </Row>

      <ModalEditFolderMessage
        onClose={(isReload) => {
          if (isReload) fetchFolder();
          setIsModalEditFolderMessage(false);
        }}
        show={isModalEditFolderMessage}
        {...folderData}
      />

      <Loader show={isLoad} />
    </>
  );
};

export default Home;
