import React from "react";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUser, faTools } from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon } from "ui-kit-ck-consultant";

import axiosConfig from "../utils/axiosConfig";
import logo from "../images/logo-white.svg";
import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  static contextType = AuthContext;

  handleSignOutClick() {
    var url = window.location.href;
    var arr = url.split("/");
    axiosConfig("/auth/signout", {
      data: {
        url: arr[0] + "//" + arr[2],
      },
    }).then((res) => {
      if (res.data.success === true) {
        document.location.href = res.data.redirect;
      }
    });
  }

  render() {
    if (this.props.connected === false) {
      return <Navigate to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <div className="Dashboard-container-body">
            <Header
              logo={logo}
              height="30"
              onClick={() => this.props.history.push("/home")}
            >
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => (document.location.href = this.context.url)}
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              {this.context.administration ? (
                <ButtonIcon
                  style={{ color: "#ffffff" }}
                  onClick={() =>
                    (document.location.href = this.context.urlAdmin)
                  }
                >
                  <FontAwesomeIcon icon={faTools} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className="Dashboard-content">{this.props.children}</div>
          </div>
        </div>
      );
    }
  }
}
