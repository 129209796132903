import React, { createRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import routes from "./routes";
import WebRTC from "./components/general/WebRTC";
import axiosConfig from "./utils/axiosConfig";
import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
ReactGA.initialize("G-EMDBKX76D7");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      id: null,
      administration: null,
      network: null,
      group: null,
      region: null,
      agency: null,
      groupName: null,
      name: null,
      url: null,
      urlAdmin: null,
    };

    this.refWebRTC = createRef();
  }

  componentDidMount() {
    if (!document.location.href.includes("client")) {
      this.checkAuth();
      this.intervalToken = setInterval(() => {
        this.checkAuth();
      }, 30 * 60 * 1000);
    } else {
      this.setState({ isReady: true });
    }
  }

  checkAuth = () => {
    const pathArray = document.location.href.split("/");
    var url = window.location.href;
    var arr = url.split("/");
    let token = null;
    if (pathArray.includes("success")) {
      token = pathArray[pathArray.length - 1];
    }
    axiosConfig("/auth/refresh-token", {
      data: {
        token: token,
        url: arr[0] + "//" + arr[2],
      },
    }).then((res) => {
      if (res.data.success && navigator.onLine) {
        if (res.data.isRedirect) {
          document.location.href = res.data.redirect;
        } else {
          if (pathArray.includes("success")) {
            document.location.href = "/";
          } else {
            const oldState = this.state;

            const newState = {
              isReady: true,
              connected: true,
              id: res.data.data.id,
              administration: res.data.data.administration,
              network: res.data.data.network_id,
              group: res.data.data.group_id,
              region: res.data.data.region_id,
              agency: res.data.data.agency_id,
              groupName: res.data.data.group,
              name: res.data.data.name,
              url: res.data.url,
              urlAdmin: res.data.urlAdmin,
            };
            if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
              this.setState(newState);
            }
          }
        }
      } else {
        this.setState({ connected: false }, () => {
          setTimeout(() => {
            document.location.href = res.data.redirect;
          }, 1000);
        });
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <AuthContext.Provider
        value={{
          id: this.state.id,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          groupName: this.state.groupName,
          name: this.state.name,
          administration: this.state.administration,
          url: this.state.url,
          urlAdmin: this.state.urlAdmin,
        }}
      >
        {this.state.connected ? <WebRTC ref={this.refWebRTC} /> : <></>}
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout connected={this.state.connected}>
                    <route.component refWebRTC={this.refWebRTC} />
                  </route.layout>
                }
              />
            ))}
          </Routes>
        </Router>
      </AuthContext.Provider>
    );
  }
}
