import React, { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "ui-kit-ck-consultant";

const Camera = ({
  firstVideoRef,
  firstVideoWidth,
  firstVideoHeight,
  firstVideoIsMuted,
  secondVideoRef,
  secondVideoWidth,
  secondVideoHeight,
  secondVideoIsMuted,
  isTakePicture,
  countLoadPicture,
  pictures,
  onDeletePicture,
  onMarker,
  isMarker,
  statusConnection,
  children,
}) => {
  const [isFullModal, setIsFullModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const canvasRef = useRef();

  const addMarker = (x, y, width = null, height = null) => {
    if (width && height) {
      x = (x * firstVideoRef.current.clientWidth) / width;
      y = (y * firstVideoRef.current.clientHeight) / height;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.beginPath();
    canvasArrow(context, x - 20, y - 20, x, y);
    context.stroke();
    getCanvasPNG();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const canvasArrow = (context, fromx, fromy, tox, toy) => {
    let headlen = 15;
    context.strokeStyle = "red";
    context.lineWidth = 3;
    let dx = tox - fromx;
    let dy = toy - fromy;
    let angle = Math.atan2(dy, dx);
    context.moveTo(fromx, fromy);
    context.lineTo(tox, toy);
    context.lineTo(
      tox - headlen * Math.cos(angle - Math.PI / 5),
      toy - headlen * Math.sin(angle - Math.PI / 5)
    );
    context.moveTo(tox, toy);
    context.lineTo(
      tox - headlen * Math.cos(angle + Math.PI / 5),
      toy - headlen * Math.sin(angle + Math.PI / 5)
    );
  };

  const getCanvasPNG = () => {
    return canvasRef.current;
  };

  const width = firstVideoRef.current
    ? firstVideoRef.current.clientWidth
    : null;
  const height = firstVideoRef.current
    ? firstVideoRef.current.clientHeight
    : null;

  if (!firstVideoIsMuted && firstVideoRef?.current) {
    firstVideoRef.current.volume = 0.95;
  }

  if (!secondVideoIsMuted && secondVideoRef?.current) {
    secondVideoRef.current.volume = 0.95;
  }

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgb(0, 0, 0)",
        zIndex: 200,
      }}
    >
      {countLoadPicture ? (
        <div
          style={{
            position: "fixed",
            left: "30px",
            bottom: "30px",
            zIndex: 1000,
          }}
        >
          <div
            className="bg-red"
            style={{ height: "15px", width: "15px", borderRadius: "15px" }}
          ></div>
        </div>
      ) : null}
      <div
        style={{
          position: "absolute",
          left: "2rem",
          top: "2rem",
          height: "120px",
          width: "120px",
          borderRadius: "60px",
          border: "2px rgba(255, 255, 255, 0.4) solid",
          backgroundColor: "rgb(0, 0, 0)",
          zIndex: 10,
          overflow: "hidden",
        }}
      >
        <video
          volume={0.95}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: secondVideoWidth < secondVideoHeight ? "100%" : "inherit",
            maxHeight:
              secondVideoHeight < secondVideoWidth ? "100%" : "inherit",
          }}
          src={null}
          autoPlay
          playsInline
          muted={secondVideoIsMuted}
          ref={secondVideoRef}
        />
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
        }}
      >
        <video
          volume={0.95}
          className={isTakePicture ? "camera-animation" : ""}
          style={{
            margin: "auto",
            width: firstVideoWidth > firstVideoHeight ? "100%" : "inherit",
            height: firstVideoHeight > firstVideoWidth ? "100%" : "inherit",
          }}
          src={null}
          autoPlay
          playsInline
          muted={firstVideoIsMuted}
          ref={firstVideoRef}
        />
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: width || "inherit",
            height: height || "inherit",
          }}
          onClick={(e) => {
            if (isMarker) {
              let currentTargetRect = e.currentTarget.getBoundingClientRect();
              const x = e.clientX - currentTargetRect.left;
              const y = e.clientY - currentTargetRect.top;
              addMarker(x, y);
              onMarker(x, y, width, height);
            }
          }}
        />
      </div>
      <div
        style={{
          margin: "auto",
          marginBottom: "1rem",
          color: "#ffffff",
          zIndex: 20,
        }}
      >
        {children}
      </div>
      {pictures && pictures.length > 0 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            width: "180px",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            zIndex: 210,
            padding: "1rem",
            overflowY: "auto",
          }}
        >
          {pictures.reverse().map((element, idx) => (
            <div
              key={idx}
              style={{
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: "40px",
                  height: "40px",
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                  cursor: "pointer",
                  color: "red",
                  borderBottomLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                onClick={() => onDeletePicture(idx)}
              >
                <FontAwesomeIcon style={{ margin: "auto" }} icon={faTrash} />
              </div>
              <img
                src={element.base64}
                alt={idx}
                style={{
                  maxWidth: "100%",
                  marginBottom: "1rem",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setIsFullModal(true);
                  setCurrentImage(element.base64);
                }}
              />
            </div>
          ))}
        </div>
      )}
      {statusConnection === 0 && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            textAlign: "center",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <p>Recherche du partenaire...</p>
        </div>
      )}
      {statusConnection === 1 && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            textAlign: "center",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <p>Connexion au partenaire...</p>
        </div>
      )}
      <Modal show={isFullModal} onClose={() => setIsFullModal(false)}>
        <img
          src={currentImage}
          alt="full"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default Camera;
