import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Status, Tr, Th, Td, ButtonIcon } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faFlag } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getStatus, getCompanyName } from "../../utils/general";

const Folder = ({
  reference,
  date,
  licensePlate,
  dateAppointment,
  hourAppointment,
  insuredName,
  insuredPhone,
  insuredEmail,
  company,
  status,
  token,
  idx,
  openModalEdit,
  deleteFolder,
  closeFolder,
}) => {
  const navigate = useNavigate();

  const handleOpenFolderClick = (e) => {
    const notClickableClasses = ["not-click", "blue", "red", "green"];
    // Empêcher l'ouverture si l'utilisateur clique sur un élément "non cliquable"
    if (!notClickableClasses.some((cls) => e.target.closest(`.${cls}`))) {
      navigate(`/folder/${token}`);
    }
  };

  const formattedDateAppointment = moment(dateAppointment).format("DD/MM/YYYY");

  return (
    <Tr className="cursor-pointer" onClick={handleOpenFolderClick}>
      <Th className="nowrap">{reference}</Th>
      <Td className="nowrap">{date}</Td>
      <Td className="nowrap">{licensePlate}</Td>
      <Td className="nowrap">
        {formattedDateAppointment} {hourAppointment}
      </Td>
      <Td className="nowrap">{insuredName}</Td>
      <Td className="nowrap">{insuredPhone}</Td>
      <Td className="nowrap">{insuredEmail}</Td>
      <Td className="nowrap">{getCompanyName(company)}</Td>
      <Td className="nowrap">
        <Status className="w-100" text={getStatus(status)} />
      </Td>
      <Td className="nowrap not-click">
        <ButtonIcon small className="blue" onClick={openModalEdit}>
          <FontAwesomeIcon icon={faPen} />
        </ButtonIcon>
        <ButtonIcon
          small
          className="red"
          onClick={() => deleteFolder(idx)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonIcon>
        {status < 4 && (
          <ButtonIcon
            small
            className="green"
            onClick={() => closeFolder(idx)}
          >
            <FontAwesomeIcon icon={faFlag} />
          </ButtonIcon>
        )}
      </Td>
    </Tr>
  );
};

// PropTypes pour valider les propriétés
Folder.propTypes = {
  reference: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
  dateAppointment: PropTypes.string.isRequired,
  hourAppointment: PropTypes.string.isRequired,
  insuredName: PropTypes.string.isRequired,
  insuredPhone: PropTypes.string.isRequired,
  insuredEmail: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  openModalEdit: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  closeFolder: PropTypes.func.isRequired,
};

export default Folder;
