import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Loader } from "ui-kit-ck-consultant";
import Peer from "peerjs";
import {
  faPhoneSlash,
  faSyncAlt,
  faMicrophoneAltSlash,
  faMicrophoneAlt,
  faVideoSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import Camera from "../../components/general/Camera";
import ButtonIcon from "../../components/general/ButtonIcon";

import axiosConfig from "../../utils/axiosConfig";
import configPeer from "../../utils/configPeer";
import {
  constraints as defaultConstraints,
  reverseCamera,
  startCamera,
  stopCamera,
  updateAudio,
  updateVideo,
} from "../../utils/camera";

const Client = () => {
  const { token } = useParams();
  const cameraRef = useRef();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  const [constraints, setConstraints] = useState(defaultConstraints);
  const [isPeer, setIsPeer] = useState(false);
  const [isCamera, setIsCamera] = useState(false);
  const [isCall, setIsCall] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraDisabled, setIsCameraDisabled] = useState(false);
  const [statusConnection, setStatusConnection] = useState(0);
  const [idUser, setIdUser] = useState(null);
  const [countLoadPicture, setCountLoadPicture] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [localVideoWidth, setLocalVideoWidth] = useState(null);
  const [localVideoHeight, setLocalVideoHeight] = useState(null);
  const [remoteVideoWidth, setRemoteVideoWidth] = useState(null);
  const [remoteVideoHeight, setRemoteVideoHeight] = useState(null);
  const [peer, setPeer] = useState(null);
  const [conn, setConn] = useState(null);
  const [call, setCall] = useState(null);

  useEffect(() => {
    getFolderClient();
  }, []);

  const getFolderClient = async () => {
    try {
      const res = await axiosConfig("folder/get-folder-client", {
        data: { token },
      });
      if (res.data.success) {
        const { folder } = res.data;
        setIdUser(folder.idUser);
        setIsLoad(false);
        if (folder.status < 4) {
          startPeerConnection();
        } else {
          alert("Le dossier est clôturé");
        }
      } else {
        handleError("Erreur lors de la récupération du dossier");
      }
    } catch (err) {
      handleError("Erreur lors de la récupération du dossier");
    }
  };

  const handleError = (message) => {
    alert(message);
    setIsLoad(false);
  };

  const startPeerConnection = () => {
    const newPeer = new Peer(`${token}_client`, configPeer);

    newPeer.on("open", () => setIsPeer(true));
    newPeer.on("call", (incomingCall) => {
      incomingCall.answer(window.stream);
      listenToCallStream(incomingCall);
      setCall(incomingCall);
    });
    newPeer.on("disconnect", () => console.log("PeerJS disconnected"));
    newPeer.on("error", (err) => console.error("PeerJS error:", err));

    setPeer(newPeer);
  };

  const listenToCallStream = (incomingCall) => {
    incomingCall.on("stream", (remoteStream) => {
      remoteVideoRef.current.srcObject = remoteStream;
      remoteVideoRef.current.addEventListener(
        "playing",
        () => {
          setRemoteVideoWidth(remoteVideoRef.current.videoWidth);
          setRemoteVideoHeight(remoteVideoRef.current.videoHeight);
          setIsCall(true);
        },
        { once: true }
      );
    });

    incomingCall.peerConnection.oniceconnectionstatechange = () => {
      const state = incomingCall.peerConnection.iceConnectionState;
      setStatusConnection(
        state === "disconnected" ? 0 : state === "connected" ? 2 : 1
      );
    };
  };

  const connectPeer = () => {
    const newConn = peer.connect(`xpertvisio_${idUser}_expert`);
    newConn.on("open", () => {
      newConn.on("data", handlePeerData);
      newConn.send({ type: "call", token });
    });
    setConn(newConn);
  };

  const handlePeerData = (data) => {
    switch (data.type) {
      case "reject-call":
        alert("L'expert est indisponible");
        handleStopCameraClick();
        break;
      case "close-call":
        handleStopCameraClick();
        break;
      case "take-picture":
        handleTakePictureClick();
        break;
      case "reverse-camera":
        handleReverseCameraClick();
        break;
      case "point-marker":
        cameraRef.current.addMarker(data.x, data.y, data.width, data.height);
        break;
      case "clear-marker":
        cameraRef.current.clearCanvas();
        break;
      default:
        console.warn("Unhandled data type:", data.type);
        break;
    }
  };

  const handleStartClick = () => {
    startCamera(constraints, (result) => {
      if (result.success) {
        window.stream = result.stream;
        setIsCamera(true);
      } else {
        alert("Erreur lors du démarrage de la caméra");
      }
    });
  };

  // Dans Client.js
  useEffect(() => {
    if (isCamera) {
      localVideoRef.current.srcObject = window.stream;

      localVideoRef.current.addEventListener(
        "playing",
        () => {
          setLocalVideoWidth(localVideoRef.current.videoWidth);
          setLocalVideoHeight(localVideoRef.current.videoHeight);

          if (!isCall) {
            connectPeer();
          }
        },
        { once: true } // L'événement ne sera écouté qu'une seule fois
      );
    }
  }, [isCamera]); // Ce useEffect se déclenchera uniquement lorsque isCamera change

  const handleStopCameraClick = (notifyExpert = false) => {
    stopCamera(() => {
      setIsCamera(false);
      setIsCall(false);
      if (notifyExpert && conn) conn.send({ type: "close-call" });
      call?.close();
      conn?.close();
    });
  };

  const handleUpdateAudioClick = () => {
    updateAudio(isMuted);
    setIsMuted(!isMuted);
  };

  const handleUpdateVideoClick = () => {
    updateVideo(isCameraDisabled);
    setIsCameraDisabled(!isCameraDisabled);
  };

  const handleReverseCameraClick = () => {
    reverseCamera(constraints, (newConstraints) => {
      setConstraints(newConstraints);
      startCamera(newConstraints, (result) => {
        if (result.success) {
          window.stream = result.stream;
          localVideoRef.current.srcObject = result.stream;
          const sender = call.peerConnection
            .getSenders()
            .find((s) => s.track.kind === "video");
          if (sender) sender.replaceTrack(window.stream.getVideoTracks()[0]);
        }
      });
    });
  };

  const handleTakePictureClick = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const video = localVideoRef.current;

    const width =
      video.videoWidth > video.videoHeight
        ? 720
        : (720 * video.videoWidth) / video.videoHeight;
    const height =
      video.videoWidth > video.videoHeight
        ? (720 * video.videoHeight) / video.videoWidth
        : 720;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, width, height);
    const date = moment().format("DD/MM/YYYY HH:mm:ss");
    context.fillStyle = "red";
    context.font = "16px Arial";
    context.fillText(date, 20, 40);

    const imageData = canvas.toDataURL("image/jpeg", 0.8);
    setCountLoadPicture(countLoadPicture + 1);
    // Upload logic...
  };

  return (
    <div className="p-20 d-flex h-100 w-100 flex-column">
      <div className="mt-auto mb-auto text-center">
        <Button
          className="m-auto"
          text="Démarrer la visioconférence"
          onClick={handleStartClick}
          disabled={!isPeer}
        />
      </div>
      {isCamera && (
        <Camera
          ref={cameraRef}
          firstVideoRef={localVideoRef}
          firstVideoWidth={localVideoWidth}
          firstVideoHeight={localVideoHeight}
          secondVideoRef={remoteVideoRef}
          secondVideoWidth={remoteVideoWidth}
          secondVideoHeight={remoteVideoHeight}
          isMuted={isMuted}
        >
          <ButtonIcon
            icon={faPhoneSlash}
            red
            onClick={() => handleStopCameraClick(true)}
          />
          <ButtonIcon
            icon={isMuted ? faMicrophoneAltSlash : faMicrophoneAlt}
            red={isMuted}
            onClick={handleUpdateAudioClick}
          />
          <ButtonIcon
            icon={isCameraDisabled ? faVideoSlash : faVideo}
            red={isCameraDisabled}
            onClick={handleUpdateVideoClick}
          />
          <ButtonIcon icon={faSyncAlt} onClick={handleReverseCameraClick} />
        </Camera>
      )}
      <Loader show={isLoad} />
    </div>
  );
};

export default Client;
